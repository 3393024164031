<template>
  <div>
    <v-row v-if="isLoading">
      <v-col cols="12">
        <SkeletonLoaders type="card-heading, list-item-three-line" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <redirect class="mb-5" title="Dicounts" route="website.discount.code" />
        <div class="page-header">
          <Notification v-if="error" :messages="error" type="error" />
          <h2 class="page-title">Update discount code</h2>
          <v-btn @click="onChangeStatus" :color="discount.discount_status == 'active' ? '#ccc' : 'primary'">
            <v-icon class="mr-2">
              fas fa-times-circle
            </v-icon>
            {{ discount.discount_status == 'active' ? 'Disabled' : 'Enabled' }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" md="8">
        <Code />
        <Options />
        <CustomOptions />
        <Minimum
          v-if="!isLoading1"
          @checkPrerequisite="checkPrerequisite()"
          :checkPrerequisiteValue="checkPrerequisiteValue"
        />
        <UsageLimits />
        <CustomerEligibility />
        <ActiveDates />
      </v-col>
      <v-col cols="12" md="4" class="px-3 py-3">
        <Summary />
      </v-col>
      <v-col cols="12" class="d-flex align-center">
        <v-btn :loading="isLoadingAction" @click="deleteDiscount" color="error">Delete</v-btn>
        <v-btn :loading="isLoadingAction" @click="submit" class="ml-auto" color="primary">Save</v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { type } from 'os';
import { required, minLength, maxLength, email, helpers, decimal } from 'vuelidate/lib/validators';
import moment from 'moment';
import Code from '../components/Code';
import Options from '../components/Options';
import ActiveDates from '../components/ActiveDates';
import CustomerEligibility from '../components/CustomerEligibility';
import Minimum from '../components/Minimum';
import UsageLimits from '../components/UsageLimits';
import CustomOptions from '../components/CustomOptions';
import Summary from '../components/Summary';
import { discountService } from '@/apis/discount';
import { mapState } from 'vuex';

export default {
  props: ['id'],
  components: {
    Code,
    Options,
    ActiveDates,
    CustomerEligibility,
    Minimum,
    UsageLimits,
    CustomOptions,
    Summary,
  },
  data() {
    return {
      isLoading1: true,
      setEndDate: false,
      usageLimit: false,
      isLoadingAction: false,
      isLoading: false,
      discount: {},
      error: null,
      discountTypes: [
        {
          title: 'Percentage',
          value: 'percentage',
        },
        {
          title: 'Fixed amount',
          value: 'fixed_amount',
        },
        {
          title: 'Free shipping',
          value: 'free_shipping',
        },
      ],
    };
  },
  validations: {
    discount: {
      discount_code: {
        required,
      },
      discount_value: {
        required,
      },
    },
  },
  computed: {
    discountOption: function() {
      return this.discountOptions.find(discount => discount.type == this.discount.discount_value_type);
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  created() {
    this.getDiscount();
  },
  methods: {
    checkPrerequisite() {
      if (
        this.discount.prerequisite_subtotal_range &&
        this.discount.prerequisite_subtotal_range.greater_than_or_equal_to
      ) {
        this.checkPrerequisiteValue = 'purchase_amount';
      } else if (
        this.discount.prerequisite_quantity_range &&
        this.discount.prerequisite_quantity_range.greater_than_or_equal_to
      ) {
        this.checkPrerequisiteValue = 'quantity_items';
      } else {
        this.checkPrerequisiteValue = 'none';
      }
    },
    async getDiscount() {
      this.isLoading = true;
      try {
        this.isLoading1 = true;
        let discountData = await discountService.get(this.id);
        this.discount = discountData.data;
        this.isLoading1 = false;
        this.checkPrerequisite();
        if (this.discount.discount_end_date) {
          this.setEndDate = true;
          this.discount.discount_end_date = moment(this.discount.discount_end_date)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        }
        if (this.discount.discount_start_date) {
          this.discount.discount_start_date = moment(this.discount.discount_start_date)
            .utc()
            .format('YYYY-MM-DD HH:mm:ss');
        }
        // console.log(this.discount.discount_start_date);
        if (this.discount.usage_limit > 0) {
          this.usageLimit = true;
        } else {
          this.usageLimit = false;
        }
        if (!this.discount.prerequisite_collection_ids) {
          this.discount.prerequisite_collection_ids = [];
        }
        if (!this.discount.prerequisite_customer_ids) {
          this.discount.prerequisite_customer_ids = [];
        }
        if (!this.discount.prerequisite_product_ids) {
          this.discount.prerequisite_product_ids = [];
        }
        if (!this.discount.prerequisite_quantity_range) {
          this.discount.prerequisite_quantity_range = {};
        }
        if (!this.discount.prerequisite_shipping_price_range) {
          this.discount.prerequisite_shipping_price_range = {};
        }
        if (!this.discount.prerequisite_subtotal_range) {
          this.discount.prerequisite_subtotal_range = {};
        }
        if (!this.discount.prerequisite_to_entitlement_quantity_ratio) {
          this.discount.prerequisite_to_entitlement_quantity_ratio = {};
        }
        if (!this.discount.prerequisite_variant_ids) {
          this.discount.prerequisite_variant_ids = [];
        }
        this.isLoading = false;
      } catch (error) {
        this.error = error;
        this.isLoading = false;
        console.log(error);
      }
    },
    async onChangeStatus() {
      if (this.discount.discount_status == 'expired') {
        this.discount.discount_status = 'active';
      } else {
        this.discount.discount_status = 'expired';
      }
      this.submit();
    },
    async submit() {
      this.isLoadingAction = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          if (this.discount.discount_start_date) {
            this.discount.discount_start_date = moment(new Date(this.discount.discount_start_date))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
          }
          if (this.discount.discount_end_date) {
            this.discount.discount_end_date = moment(new Date(this.discount.discount_end_date))
              .utc()
              .format('YYYY-MM-DD HH:mm:ss');
          }
          let discountData = await discountService.update(this.discount);
          this.getDiscount();
          this.isLoadingAction = false;
        } catch (error) {
          this.isLoadingAction = false;
          this.error = error;
          console.log(error);
        }
      } else {
        this.isLoadingAction = false;
      }
    },
    async deleteDiscount() {
      this.isLoadingAction = true;
      try {
        let discountData = await discountService.deleteById(this.id);
        this.isLoadingAction = false;

        this.$router.push({ name: 'website.discount.code' });
      } catch (error) {
        this.isLoadingAction = false;
        this.error = error;
        console.log(error);
      }
    },
  },
};
</script>
